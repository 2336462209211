import {
  Badge, Card, CardContent, IconButton, Typography, Box, List, ListItem, ListItemText, Button, Divider, Popover,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useNavigate } from 'react-router-dom';
import socket from '../../service/socketService';
import { fetchUnreadArtifacts, markAsRead } from '../../utils/utils';

const Notification = () => {
  const notificationRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [openedArtifactId, setOpenedArtifactId] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const currentUserId = localStorage.getItem('user_id');

  useEffect(() => {
    const getUnreadArtifacts = async () => {
      const data = await fetchUnreadArtifacts();
      if (data) {
        setNotifications(data);
      }
    };

    getUnreadArtifacts();
  }, []);

  useEffect(() => {
    if (openedArtifactId) {
      markAsRead([openedArtifactId]);
    }
  }, [openedArtifactId]);

  useEffect(() => {
    socket.on('newMessage', (data) => {
      if (data.SenderUserId !== currentUserId) {
        setNotifications((prevNotifications) => {
          const existingNotification = prevNotifications.find((notification) => notification.artifact_id === data.artifactId);

          if (existingNotification) {
            return prevNotifications.map((notification) => (notification.artifact_id === data.artifactId
              ? {
                ...notification,
                count: data.unreadCount,
                read: false,
              }
              : notification));
          }
          return [
            ...prevNotifications,
            {
              artifact_id: data.artifactId,
              artifact_name: data.artifactName,
              count: data.unreadCount,
              read: false,
            },
          ];
        });
      }
    });

    return () => {
      socket.off('newMessage');
    };
  }, [currentUserId]);

  const handleMarkAllRead = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const artifact_ids = notifications.map((notification) => notification.artifact_id);
    setNotifications((prevNotifications) => prevNotifications.map((notification) => ({ ...notification, read: true })));
    await markAsRead(artifact_ids);
  };

  const handleRead = (artifact_id: string) => {
    setNotifications((prevNotifications) => prevNotifications.map((notification) => (notification.artifact_id === artifact_id ? { ...notification, read: true } : notification)));
  };

  const handleNotificationClick = (artifact_id: string) => {
    handleRead(artifact_id);
    setOpenedArtifactId(artifact_id);

    navigate(`/artifacts/viewArtifact/${artifact_id}`, { state: { showChat: true } });
    handleClose();
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.artifact_id !== artifact_id));
  };

  // const unreadCount = notifications.filter((notification) => !notification.read).length;
  const unreadCount = notifications?.length;

  return (
    <Box position="relative" display="inline-block" ref={notificationRef}>
      <IconButton
        size="large"
        edge="end"
        aria-label="notifications"
        color="inherit"
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        }}
        onClick={handleClick}
      >
        <Badge
          badgeContent={unreadCount}
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: 10,
              height: 16,
              width: 16,
              borderRadius: '50%',
            },
          }}
        >
          <NotificationsNoneOutlinedIcon fontSize="large" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          boxShadow: 1,
          borderRadius: '50px',
        }}
      >
        <Card
          sx={{
            width: 500,
          }}
        >
          <CardContent sx={{ p: 2, maxHeight: 600, overflowY: 'auto' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Notifications
              </Typography>
              <Button size="small" onClick={handleMarkAllRead}>
                Mark all as read
              </Button>
            </Box>
            <Divider sx={{ mb: 1 }} />
            <List>
              {notifications.map((notification, index) => (
                <React.Fragment key={notification.artifact_id}>
                  <ListItem
                    sx={{
                      backgroundColor: notification.read ? 'white' : 'rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: notification.read ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.1)',
                      },
                      width: '100%',
                      mb: 1,
                    }}
                    onClick={() => handleNotificationClick(notification.artifact_id)}
                  >
                    <ListItemText
                      primary={(
                        <Box display="flex" justifyContent="space-between">
                          <Typography>
                            {'You have '}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                              {notification.count}
                            </Typography>
                            {` ${notification.count === 1 ? 'comment' : 'comments'} on `}
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                              {notification.artifact_name}
                            </Typography>
                          </Typography>
                        </Box>
                      )}
                      sx={{ fontWeight: notification.read ? 'normal' : 'bold' }}
                    />
                  </ListItem>
                  {index < notifications.length - 1 && <Divider sx={{ width: '100%' }} />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};

export default Notification;
